import React from 'react';
import { useState, useEffect } from 'react';
import "./Banner.css";
import bannerImg from "../../../images/service-digital.jpg";

const Banner = () => {
    const [banner, setBanner] = useState([]);

    useEffect(() => {
        fetch('https://digitalbackend.techsistltd.com/digital_service_details/')
            .then(res => res.json())
            .then(data => setBanner(data.data))
    }, [])
    return (
        <div className='container-fluid banner p-0 '>
            {/* <div className='banner-img'>
            <h2 className='banner-title'>{banner.title}</h2>
        </div> */}
            <img src={bannerImg} className="banner-img" alt="" />
        </div>
    );
};

export default Banner;