import { useEffect, useState } from "react"

const useDigitalService = () => {

    const [service, setService] = useState([]);
    // console.log(service);

    useEffect(() => {
        fetch('https://digitalbackend.techsistltd.com/digital_service_details/')
            .then(res => res.json())
            .then(data => setService(data.data))
    }, [])

    return [service];
}
export default useDigitalService;